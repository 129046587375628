<template>
  <div class="app">
    <div class="app-body" :style="d_showAplicationHeader ? 'margin-bottom: 0px;' : 'margin-top: 0px;'">
      <!-- <AppSidebar fixed v-if="d_showAplicationSidebar">
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="navItems"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar> -->
      <main class="main">
        <!-- <Breadcrumb :list="list" /> -->
        <div id="router-div">
          <router-view></router-view>
        </div>
      </main>
      <!--       <AppAside fixed>
        <DefaultAside />
      </AppAside> -->
    </div>
    <div v-if="d_showRightMenu && user" class="left-page-close-button">
      <b-button @click="d_showRightMenu = false" size="lg" style="padding: 0px;" variant="secondary"><img src="@/icon/1890926.png" width="30px;" /></b-button>
    </div>
    <div v-if="d_showRightMenu && user" class="left-page-fullscreen-button">
      <b-button variant="secondary" @click="f_goToAllTasksForAllOrganizations()" size="lg" style="padding: 0px;">
        <img src="@/icon/1568887.png" width="30px;" />
      </b-button>
    </div>
    <div class="wisdom-right-menu" v-if="d_showRightMenu && user" @mouseleave="">
      <task-list :right_menu="true"></task-list>
    </div>
    <b-modal id="store-loading" v-model="ShowLoadingModal" no-close-on-esc no-close-on-backdrop centered hide-footer hide-header-close body-bg-variant="dark" style="border-radius: 30px!important; z-index: 9999; box-shadow: -2px -2px;">
      <b-row v-if="StoreLoading.data.label" style="vertical-align: middle; text-align: center;">
        <b-col sm="12" lg="1">
          <!-- <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
          <img src="../../public/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 30px; height: 30px;" />
        </b-col>
        <b-col sm="12" lg="11">
          <div style="padding-top: 5px;">{{ StoreLoading.data.label }}</div>
          <template v-if="d_progressData">
            <b-progress :value="d_progressData.percent" variant="success" show-progress striped :animated="true" class="mb-2"></b-progress>
            <div style="padding-top: 5px;">
              <span v-if="d_progressData.total_process_count"> Toplam İşlem: {{ d_progressData.total_process_count }} </span>
              <span v-if="d_progressData.process_count">İşlem Adedi: {{ d_progressData.process_count }} </span>
            </div>
            <div v-if="d_progressData.message" style="padding-top: 5px;"> Mesaj: {{ d_progressData.message }} </div>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="store-modal" v-model="show_store_modal" :title="StoreModal.data.title ? StoreModal.data.title : 'Uyarı'" :centered="StoreModal.data.centered ? StoreModal.data.centered : true" :header-bg-variant="StoreModal.data.header_bg_variant ? StoreModal.data.header_bg_variant : 'info'" :header-text-variant="StoreModal.data.header_text_variant ? StoreModal.data.header_text_variant : 'dark'">
      <div style="white-space: pre-line;" v-if="StoreModal.data.text">{{ StoreModal.data.text }}</div>
      <template slot="modal-footer">
        <b-button v-if="StoreModal.data.type === 'alert'" @click="show_store_modal = false">{{ $t('wdm16.11212') }}</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="show_store_modal = false">{{ $t('wdm16.3975') }}</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="ConfirmStoreModal()">İşlemi gerçekleştir</b-button>
      </template>
    </b-modal>
    <b-modal id="d_showAgreementModal.show" v-model="d_showAgreementModal.show" title="Sözleşme ve Lisans Kabul İşlemleri" centered hide-footer no-close-on-esc centered no-stacking no-close-on-backdrop hide-header-close header-bg-variant="info" header-text-variant="dark">
      <agreement-modal :p_showThisModal="d_showAgreementModal" :p_missingAgreement="d_missingAgreement"></agreement-modal>
    </b-modal>
    <b-modal v-if="d_showWisdomeraApps" v-model="d_showWisdomeraApps" size="sm" title="Uygulamalar" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <wisdomera-apps></wisdomera-apps>
    </b-modal>
  </div>
</template>

<script>
// import {default as WisdomeraApps} from '@/components/widgets/WisdomeraApps';
// import UserService from '@/services/users';
// import WdmService from '@/services/wdm';
import { DateFunctions } from '@/services/public/date_functions';
import moment from "moment";
import auth from '@/auth';
// import {default as AgreementModal } from "@/components/widgets/AgreementModal";
import _nav from '@/_nav';
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomSocket
} from '@/components/socket/index';
import store from '@/store';

export default {
  name: 'DefaultContainer',
  components: {
    Modal,
    WisdomSocket
  },
  computed: {
    ...mapGetters({
      sidebar: 'sidebar',
      device: 'device',
      help: 'help',
      StoreModal: 'modal',
      StoreTest: 'test',
      StoreLoading: 'loading',
      account_list: 'account_list',
      StoreUser: 'user',
      st_version: 'version'
    }),
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.value)
    }
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  created: function () {
    if (['showonedocument', 'wdmr'].indexOf(this.$route.name) !== -1) {
      this.d_showAplicationHeader = false;
      this.d_showAplicationSidebar = false;
      this.d_showAplicationFooter = false;
    }
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_wisdomEraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
    if (JSON.parse(localStorage.getItem('account_list'))) {
      this.$store.commit('account_list', JSON.parse(localStorage.getItem('account_list')));
    }
    // this.f_activeLanguage();
    // this.f_getHeaderTreeList();
  },
  destroyed () {
    clearInterval(this.d_flowingTextInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  beforeCreate: function () {},
  mounted: function () {
    _nav.f_updateItems();
    this.navItems = _nav.items;
    // auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'default');
    if (this.StoreLoading.status) {
      this.ShowLoadingModal = true;
    }
    if (this.$options.sockets) {
      this.$options.sockets.onmessage = function (message) {
        let socket_data = JSON.parse(message.data);
        if (socket_data.type && ['show_convert_progress'].indexOf(socket_data.type) !== -1) {
          if (this.showProgresBarInterval) {
            // clearInterval(this.showProgresBarInterval);
            this.showProgresBarInterval = 0;
          }
          this.d_progressData = socket_data.data;
          // console.log(this.d_progressData.process_count);
          this.$forceUpdate();
          // this.showNotification({ title: 'Karar Destek Sistemi', message: rule_message, type: 'success', timeout: 5000 });
          // this.run_rule(socket_data);
          // console.log(socket_data);
        }
      }
    }
    // if (this.d_wisdomEraSystem && this.d_wisdomEraSystem.agreement) {
    //   this.f_checkAgreements();
    // }
  },
  data () {
    return {
      d_showAplicationFooter: true,
      d_showAplicationSidebar: true,
      d_showAplicationHeader: true,
      d_showWisdomeraApps: false,
      d_showSideBar: true,
      w_presentation: false,
      d_progressData: '',
      d_showWisdomEraSupport: false,
      DateFunctions: DateFunctions,
      changeAccountWithoutPasswordModal: false,
      ShowLoadingModal: false,
      show_store_modal: false,
      store: store,
      navItems: _nav.items,
      user: {},
      d_expandAllClosedCollapse: true,
      filterTable: '',
      d_showRightMenu: false,
      d_showMyProtocols: false,
      d_wisdomEraSystem: {},
      d_showAgreementModal: { 'show': false, 'type': '' },
      d_missingAgreement: [],
      d_activeLanguage: '',
      d_headerTreeList: {
        'list': [],
        'parent': {
          'key': 'wdm232_20',
          'type': 'wdm232',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm231_v2.0',
          'type': 'wdm231',
          'bucket': 'wisdom',
          'other_possible_relations': []
        }
      },
      d_allFlowingText: {
        'list': [],
        'list_index': 0,
        'text': '',
        'index': 0
      },
      d_flowingTextInterval: 0
    }
  },
  methods: {
    f_getHeaderTreeList: function () {
      let data = {
        'parent_wdm': this.d_headerTreeList.parent,
        'child_wdm': this.d_headerTreeList.child,
        'relation_type': 'keys',
        'type': 'get_wdmr_data', // only_relation_list or get_wdmr_data
        'layer_type': 'first' // first or all
      };
      WdmService.get_wdmr_tree_list_remote(data)
        .then(resp => {
          if (resp.data.status === "success") {
            if (resp.data.result.status === 'success') {
              this.d_headerTreeList.list = resp.data.result.result.list;
              // this.f_prepareExplanationListForFlow();
              this.f_prepareNameOfWdm231ForFlow();
              this.f_startFlowingText();
            }
          } else {
            console.log('errorr get_wdmr_tree_list_remote : ', resp.data.message);
          }
        });
    },
    f_prepareNameOfWdm231ForFlow: function () {
      for (let i in this.d_headerTreeList.list) {
        let x = this.d_headerTreeList.list[i];
        let flow_text = { 'label': '                        ' + x.label, 'value': x.key };
        this.d_allFlowingText.list.push(flow_text);
      }
    },
    f_prepareExplanationListForFlow: function () {
      for (let i in this.d_headerTreeList.list) {
        let x = this.d_headerTreeList.list[i];
        let flow_text = { 'label': '', 'value': x.key };
        if (x.wdmr.data && x.wdmr.data.general && x.wdmr.data.general.info && x.wdmr.data.general.info.list && x.wdmr.data.general.info.list.length > 0) {
          for (let k in x.wdmr.data.general.info.list) {
            let info_data = x.wdmr.data.general.info.list[i];
            if (info_data.explanation && info_data.explanation.val) {
              flow_text.label += info_data.explanation.val;
            }
          }
        }
        this.d_allFlowingText.list.push(flow_text);
      }
    },
    f_startFlowingText: function () {
      // when we start, we defined the list index we are flowing on.
      if (this.d_allFlowingText.list.length > 0) {
        this.d_allFlowingText.text = this.d_allFlowingText.list[0].label;
        this.d_allFlowingText.list_index = 0;
        this.d_flowingTextInterval = setInterval(function () {
          if (this.d_allFlowingText.index !== this.d_allFlowingText.text.length) {
            this.d_allFlowingText.index += 1;
          } else {
            this.d_allFlowingText.index = 0;
            if (this.d_allFlowingText.list_index !== this.d_allFlowingText.list.length - 1) {
              this.d_allFlowingText.list_index += 1;
              this.d_allFlowingText.text = this.d_allFlowingText.list[this.d_allFlowingText.list_index].label;
            } else {
              this.d_allFlowingText.list_index = 0;
              this.d_allFlowingText.text = this.d_allFlowingText.list[0].label;
            }
          }
        }.bind(this), 100);
      }
    },
    f_activeLanguage: function () {
      if (this.user && this.user.active_lang) {
        this.f_saveLanguageChange(this.user.active_lang, true);
      } else {
        if (this.d_wisdomEraSystem && this.d_wisdomEraSystem.active_lang && this.d_wisdomEraSystem.active_lang.value) {
          this.f_saveLanguageChange(this.d_wisdomEraSystem.active_lang.value);
        } else if (localStorage.getItem('active_lang')) {
          this.f_saveLanguageChange(localStorage.getItem('active_lang'));
        }
      }
    },
    f_saveLanguageChange: function (new_language, dont_change = false) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.10990') } });
      if (this.user) {
        if (dont_change) {
          localStorage.setItem('active_lang', new_language);
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.$store.commit('lang', new_language);
          this.d_activeLanguage = new_language;
        } else {
          UserService.save_language_change({ 'active_lang': new_language })
            .then(resp => {
              if (resp.data.status === 'success') {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                this.user.active_lang = new_language;
                localStorage.setItem('user', JSON.stringify(this.user));
                localStorage.setItem('active_lang', new_language);
                // auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'this', new_language);
                this.$store.commit('lang', new_language);
                this.d_activeLanguage = new_language;
              } else {
                alert('error' + resp.data.message);
              }
            });
        }
      } else {
        // auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'this', new_language);
        this.$store.commit('lang', new_language);
        localStorage.setItem('active_lang', new_language);
        this.d_activeLanguage = new_language;
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_checkAgreements: function () {
      this.d_missingAgreement = [];
      if (this.user) {
        if (!this.user.agreement) {
          for (let i in this.d_wisdomEraSystem.agreement) {
            this.d_missingAgreement.push(i);
          }
        } else {
          for (let i in this.d_wisdomEraSystem.agreement) {
            if (!this.user.agreement[i]) {
              this.d_missingAgreement.push(i);
            } else if (this.user.agreement[i] && this.user.agreement[i].list.length === 0) {
              this.d_missingAgreement.push(i);
            } else if (this.user.agreement[i].list[this.user.agreement[i].list.length - 1].agreement.version !== this.d_wisdomEraSystem.agreement[i].list[this.d_wisdomEraSystem.agreement[i].list.length - 1].version) {
              this.d_missingAgreement.push(i);
            }
          }
        }
        if (this.d_missingAgreement.length > 0) {
          this.d_showAgreementModal.type = 'user';
          this.d_showAgreementModal.show = true;
        }
      } else {
        if (!localStorage.getItem('agreement')) {
          for (let i in this.d_wisdomEraSystem.agreement) {
            this.d_missingAgreement.push(i);
          }
        } else {
          let local_agreement = JSON.parse(localStorage.getItem('agreement'));
          for (let i in this.d_wisdomEraSystem.agreement) {
            if (!local_agreement[i]) {
              this.d_missingAgreement.push(i);
            } else if (local_agreement[i] && local_agreement[i].list.length === 0) {
              this.d_missingAgreement.push(i);
            } else if (local_agreement[i].list[local_agreement[i].list.length - 1].agreement.version !== this.d_wisdomEraSystem.agreement[i].list[this.d_wisdomEraSystem.agreement[i].list.length - 1].version) {
              this.d_missingAgreement.push(i);
            }
          }
        }
        if (this.d_missingAgreement.length > 0) {
          this.d_showAgreementModal.type = 'guest';
          // this.d_showAgreementModal.show = true;
        }
      }
    },
    f_changePresentationMode: function () {
      if (this.w_presentation) {
        this.w_presentation = false;
        localStorage.setItem('presentation', false);
      } else {
        this.w_presentation = true;
        localStorage.setItem('presentation', true);
      }
    },
    f_goToCustomerSupport: function () {
      this.$router.push({ path: '/whsmodules/administrator/customer-support' });
    },
    f_goToAllTasksForAllOrganizations: function () {
      this.$router.push({ path: '/whsmodules/organization-task' });
      this.d_showRightMenu = false;
    },
    f_calculateLabTestClass: function (test) {
      if (test.val) {
        if (test.clb !== undefined && test.clb !== '' && test.clb !== null) {
          if (test.val < test.clb) {
            return 'clb-value';
          }
        }
        if (test.cub !== undefined && test.cub !== '' && test.cub !== null) {
          if (test.val > test.cub) {
            return 'cub-value';
          }
        }
        if (test.ub !== undefined && test.ub !== '' && test.ub !== null) {
          if (test.val > test.ub) {
            return 'ub-value';
          }
        }
        if (test.lb !== undefined && test.lb !== '' && test.lb !== null) {
          if (test.val < test.lb) {
            return 'lb-value';
          }
        }
        return '';
      }
    },
    DateFormat: function (date) {
      if (date) {
        if (date.indexOf('T') === -1) {
          return moment(date, "YYYY-MM-DD hh:mm:ss").format("DD.MM.YYYY");
        } else {
          return moment(date, "YYYY-MM-DDThh:mm:ss").format("DD.MM.YYYY HH:mm");
        }
      } else {
        return "";
      }
    },
    f_addDefaultTextToHims: function () {
      for (let protocol_no in this.hims_protocol_list) {
        if (this.hims_protocol_list[protocol_no].send_status) {
          for (let p in this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order']) {
            let param = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order'][p];
            let param_data = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param];
            if (param_data['required'] && !this.hims_protocol_list[protocol_no][param] && param_data.default !== 'user_will_fill') {
              if (param_data.default === 'today') {
                let today = new Date();
                this.hims_protocol_list[protocol_no][param] = moment(today).toISOString();
              } else {
                this.hims_protocol_list[protocol_no][param] = param_data.default;
              }
            }
          }
        }
      }
      this.d_showHimsProtocols = false;
      this.f_saveHimsDataContinue();
    },
    changeMultiAccount: function (account) {
      let account_login_data = {
        'username': account.username,
        'password': account.password,
        'multi_account_password': 1,
        'not_control_password': 0
      };
      // this.$router.push('/settings/user');
      auth.login(this, account_login_data, '/dashboard').then(resp => {
        _nav.f_updateItems();
        this.navItems = _nav.items;
        location.reload();
      }, resp => {
        _nav.f_updateItems();
        this.navItems = _nav.items;
        location.reload();
      });
    },
    ConfirmStoreModal: function () {
      let data = JSON.parse(JSON.stringify(this.StoreModal.data));
      data.confirm = true;
      this.$store.commit('modal', data);
      this.show_store_modal = false;
    },
    go_to_home: function () {
      if (this.user) {
        this.$router.push({ path: '/', query: {} });
      } else {
        this.$router.push({ path: '/web/dashboard', query: {} });
      }
    },
    d_showSideBarTrueFalse: function () {
      this.d_showSideBar = false;
      setTimeout(function () {
        this.d_showSideBar = true;
      }.bind(this), 500);
    },
    f_showProgressBarByInterval: function () {
      this.showProgresBarInterval = setInterval(() => {
        if (!this.d_progressData) {
          this.d_progressData = {};
        }
        if (this.d_progressData.percent === undefined) {
          this.d_progressData.percent = 5;
        } else {
          if (this.d_progressData.percent <= 50) {
            this.d_progressData.percent += 4;
          } else if (this.d_progressData.percent > 50 && this.d_progressData.percent <= 75) {
            this.d_progressData.percent += 3;
          } else if (this.d_progressData.percent > 75 && this.d_progressData.percent <= 85) {
            this.d_progressData.percent += 2;
          } else if (this.d_progressData.percent > 85 && this.d_progressData.percent < 99) {
            this.d_progressData.percent += 1;
          }
        }
        this.$forceUpdate();
      }, 500);
    }
  },
  watch: {
    'StoreUser': function () {
      this.user = JSON.parse(localStorage.getItem('user'));
      let user_send = { 'username': this.user['username'], 'hospital_group': this.user['hospital_group'], 'hospital_id': this.user['hospital_id'], 'department': this.user['department'] };
      this.$socket.send(JSON.stringify({ 'type': 'user_change', 'user': user_send }));
      this.changeAccountWithoutPasswordModal = false;
      // auth.last_wisdomera_version_control();
      this.$forceUpdate();
    },
    'show_store_modal': function () {
      if (!this.show_store_modal) {
        this.$store.commit('modal_show', false);
      }
    },
    'StoreLoading.status': function () {
      if (this.StoreLoading.status) {
        this.ShowLoadingModal = true;
        this.d_progressData = '';
        this.f_showProgressBarByInterval();
      } else {
        this.ShowLoadingModal = false;
        this.d_progressData = '';
        clearInterval(this.showProgresBarInterval);
      }
    },
    'StoreModal.show': function () {
      if (this.StoreModal.show) {
        this.show_store_modal = true;
      } else {
        this.show_store_modal = false;
        if (this.StoreModal.data.function_name) {
          if (this.StoreModal.data.function_name === 'f_addDefaultTextToHims' && this.StoreModal.data.confirm) {
            this.f_addDefaultTextToHims();
          }
        }
      }
    }
  }
}

</script>

<style type="text/css">
.clb-value {
  color: red;
  font-weight: bold;
}

.cub-value {
  color: red;
  font-weight: bold;
}

.lb-value {
  color: red;
  font-weight: bold;
}

.ub-value {
  color: red;
  font-weight: bold;
}

.right-footer-icon {
  width: 2em;
  cursor: pointer;
  float: right;
  margin-right: 5px;
}

.right-footer-icon:hover {
  width: 2.2em;
  cursor: pointer;
}

.header-icon {
  width: 2.5em;
  cursor: pointer;
  float: right;
}

.header-icon:hover {
  width: 3em;
  cursor: pointer;
}

.left-page-close-button {
  position: fixed;
  right: 0px;
  top: 55px;
  z-index: 1101;
}

.left-page-fullscreen-button {
  position: fixed;
  right: 35px;
  top: 55px;
  z-index: 1101;
}

</style>

